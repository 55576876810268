.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MuiIconButton-root {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.video-container:hover .MuiIconButton-root {
  opacity: 1;
}
