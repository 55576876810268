html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  font-weight: 500;
}

.app-container {
  max-width: 1920px;
  overflow: hidden;
}

.header-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: 94px;
  background-color: black;
}

@media (max-width: 776px) {
  .header-container {
    height: 110px;
    display: flex;
    justify-content: center;
  }
}
