.vagaro {
  color: black;
  background: white;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 3px;
  font-family: montserrat;
  font-weight: 600;
}

.vagaro-video {
  display: none;
}

.btn-hover:hover {
  border: 1px solid black;
}

.btn-footer {
  width: 200px;
  height: 35px;
  margin-top: 6px;
}

.certified {
  height: 1000px;
  overflow: hidden;
  margin-bottom: 45px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
}

@media (max-width: 1200px) {
  .certified {
    height: 700px;
    background-size: cover;
    background-position: 50% 50%;
  }
}

@media (max-width: 576px) {
  .vagaro-video {
    display: block;
  }
  .certified {
    height: 500px;
    background-size: cover;
    background-position: 50% 75%;
  }
  .btn-footer {
    margin-top: -10px;
    margin-bottom: 30px;
    width: 275px;
  }
}
